import type { CardFlowEvent } from 'types/story';
import { generateCardEventId } from 'utils/generate-id';
import { CONNECTION_TYPES, FLOW_DEFAULT_EVENT_NAME } from 'common/constants';

type CardEventProps = {
	_id?: CardFlowEvent['_id'];
	name?: CardFlowEvent['name'];
	componentId?: CardFlowEvent['componentId'];
	score?: CardFlowEvent['score'];
	rate?: CardFlowEvent['rate'];
	pointId?: string;
};

/**
 * Create story card event model
 * @param props {Object}
 * @param props._id {String} unique event id
 * @param props.name {String} event name (expected to receive component type)
 * @param props.componentId {String} id of component the event related
 * @param props.pointId {String} id of card the event points to. (optional)
 * @param props.rate {Array<Number, Number>} min & max rate values for A/B nav (optional)
 * @param props.score {Array<Number, Number>} min & max story score values (optional)
 * @return {{_id: CardEvent.props._id, name: (CardEvent.props.name|string), connection: {type: *}}}
 * @constructor
 */
export function CardEvent(props: CardEventProps = {}) {
	const event: CardFlowEvent = {
		_id: props._id || generateCardEventId(),
		name: props.name || FLOW_DEFAULT_EVENT_NAME,
	};

	if (props.componentId !== undefined) event.componentId = props.componentId;
	if (props.rate !== undefined) event.rate = props.rate;
	if (props.score !== undefined) event.score = props.score;

	event.connection = { type: CONNECTION_TYPES.NEXT };

	if (props.pointId !== undefined) {
		event.connection.type = CONNECTION_TYPES.ANY;
		event.connection.pointId = props.pointId;
	}

	return event;
}
