import React from 'react';
import cn from 'classnames';
import { get } from 'lodash';
import { Field, change } from 'redux-form';

import translate from 'utils/translate';
import { useAdminDispatch } from 'admin/reducers';
import { Hint, Select, Toggle, type SelectOption } from 'admin/components/common/Form';
import Text from 'admin/components/common/Text';
import {
	ELEMENT_OPTIONS,
	DARK_MODE_URL_PARAM,
	DARK_MODE_URL_PARAM_VALUE,
	defaultProps,
	DARK_MODE_ELEMENTS,
} from 'client/components/pages/Story/DarkMode/constants';

import type { SettingsTabsGeneralProps } from '../types';
import css from './DarkMode.scss';

interface Props extends SettingsTabsGeneralProps {
	className?: string;
}

const t = (path: string, options?: any) => translate(`story.settings.darkMode.${path}`, options);
const pickElements = (elements: string[] = []): SelectOption[] => {
	return elements.map(value => ELEMENT_OPTIONS.find(opt => opt.value === value)!).filter(Boolean);
};
const defaultElements = pickElements(defaultProps.elements);

const DarkMode: React.FC<Props> = props => {
	const { className } = props;
	const dispatch = useAdminDispatch();
	const [elements, setElements] = React.useState<SelectOption[]>(defaultElements);

	const formElements = get(props.formValues, props.fields.dmElements.name(props.version));
	React.useEffect(() => {
		// reset on form reset
		if (!props.formInfo.dirty) setElements(formElements ? pickElements(formElements) : defaultElements);
	}, [formElements, props.formInfo.dirty]);

	return (
		<div className={cn(className)}>
			<Text className={css.title} size="subheading" weight="bold" text={t('title')} />

			<div className={css.field}>
				<Field
					name={props.fields.dmAutoDetection.name(props.version)}
					component={Toggle}
					theme="dark"
					label={{ children: t('autoDetection'), component: 'label' }}
					stretch
				/>
				<Hint className={css.hint} text={t('autoDetectionDescription')} theme="dark" />
			</div>

			<div className={css.field}>
				<Field
					name={props.fields.dmShowToggle.name(props.version)}
					component={Toggle}
					theme="dark"
					label={{ children: t('showToggle'), component: 'label' }}
					stretch
				/>
				<Hint className={css.hint} text={t('showToggleDescription')} theme="dark" />
			</div>

			<div className={css.field}>
				<Field
					name={props.fields.dmEnableByUrl.name(props.version)}
					component={Toggle}
					theme="dark"
					label={{ children: t('enableByUrl'), component: 'label' }}
					stretch
				/>
				<Hint
					className={css.hint}
					text={
						<div
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: t('enableByUrlDescription', {
									query: `${DARK_MODE_URL_PARAM}=${DARK_MODE_URL_PARAM_VALUE}`,
								}),
							}}
						/>
					}
					theme="dark"
				/>
			</div>

			<div className={css.field}>
				<Select
					isMulti
					closeMenuOnSelect={false}
					label={{ children: t('elementSelection'), component: 'label' }}
					options={ELEMENT_OPTIONS}
					theme="dark"
					value={elements}
					eventListeners={{
						onChange: selected => {
							const nextSelected = selected.length
								? selected
								: pickElements([DARK_MODE_ELEMENTS.BACKGROUNDS]);

							setElements(nextSelected as SelectOption[]);

							dispatch(
								change(
									props.formInfo.name,
									props.fields.dmElements.name(props.version),
									nextSelected.map((v: SelectOption) => v.value)
								)
							);
						},
					}}
				/>
				<Hint className={css.hintWithMargin} text={t('elementSelectionDescription')} theme="dark" />
			</div>
		</div>
	);
};

export default DarkMode;
