export const DARK_MODE_ELEMENTS = {
	BACKGROUNDS: 'bgs',
	TEXTS: 'txts',
	IMAGES: 'imgs',
} as const;

export const ELEMENT_OPTIONS = [
	{ value: DARK_MODE_ELEMENTS.BACKGROUNDS, label: 'Backgrounds' },
	{ value: DARK_MODE_ELEMENTS.TEXTS, label: 'Texts' },
	{ value: DARK_MODE_ELEMENTS.IMAGES, label: 'Images' },
];

export const DARK_MODE_URL_PARAM = 'darkmode';
export const DARK_MODE_URL_PARAM_VALUE = 'true';

export const defaultProps = {
	enableByUrl: true,
	elements: [DARK_MODE_ELEMENTS.BACKGROUNDS, DARK_MODE_ELEMENTS.TEXTS],
};
