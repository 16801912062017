import React from 'react';
import { ChangeAction, Field } from 'redux-form';
import { map } from 'lodash';
import type { CardFlowEvent } from 'types/story';
import { CardEvent } from 'common/utils/facades/templates/card-event';
import Text from 'admin/components/common/Text';
import Button from 'admin/components/common/Button';
import { createTemplateName } from 'admin/utils/create-template-name';
import { Context as ThemeContext } from 'admin/components/common/Theme';
import { Select, TextField } from 'admin/components/common/Form';
import { fields } from 'admin/components/pages/Story/Flow/EditCardModal/Form';
import { useAdminSelector } from 'admin/reducers';
import { selectStoryFacade } from 'admin/reducers/story-editor/selectors';
import { Icon } from 'admin/components/common/Icon';
import { EVENT_NAME_MAX_LEN } from 'admin/components/common/Form/validations';
import css from './EditCardModal.scss';

type Props = {
	urlParam?: string;
	events: CardFlowEvent[];
	change: ChangeAction;
};

const UrlParamEvents: React.FC<Props> = props => {
	const context = React.useContext(ThemeContext);
	const storyFacade = useAdminSelector(selectStoryFacade);
	const options =
		storyFacade.settings.integrations?.urlParams?.params?.map(param => ({
			value: param,
			label: param,
		})) ?? [];

	if (options.length === 0) {
		options.push({ value: '', label: 'No URL params available' });
	}

	const addEvent = () => {
		props.change('events', [
			...props.events,
			CardEvent({
				name: createTemplateName({ name: '', exclude: map(props.events, o => o.name) })
					.trim()
					.toLowerCase(),
			}),
		]);
	};

	return (
		<div className={css.urlParams}>
			<div className={css.urlParamsWrap}>
				<Field
					name={fields.urlNavigationParam}
					component={Select}
					menuPosition="fixed"
					label={{ children: 'Select URL param' }}
					options={options}
					theme={context?.theme}
				/>
			</div>
			{props.urlParam && (
				<div className={css.urlParamsValues}>
					<Text className={css.valuesLabel} size="label" weight={Text.weight.bold} text="Values" />
					{props.events.map((event, index) => (
						<div className={css.eventWrap} key={`url-param-event-${event._id}`}>
							<Field
								name={fields.eventName(index)}
								component={TextField}
								isRequired
								props={{
									limit: { max: EVENT_NAME_MAX_LEN, position: TextField.limitPosition.in },
									theme: context?.theme,
									isLabelUppercase: false,
									isRequired: true,
								}}
							/>
							<button
								type="button"
								className={css.removeBtn}
								onClick={() =>
									props.change(
										'events',
										props.events.filter((_, i) => i !== index)
									)
								}
							>
								<Icon className={css.removeBtnIcon} type="minus-rounded" />
							</button>
						</div>
					))}
					<Button className={css.addBtn} view="secondary-gray" onClick={() => addEvent()}>
						<div className={css.addBtnLabel}>
							<Icon className={css.addBtnLabelIcon} type="plus-rounded" />
							<Text size="label" weight="normal" text="Add Value" />
						</div>
					</Button>
				</div>
			)}
		</div>
	);
};

export default UrlParamEvents;
