export const fields = {
	name: {
		name: () => 'name',
	},
	domainId: {
		name: () => 'domainId',
	},
	clientStoryId: {
		name: () => 'clientStoryId',
	},
	storycardsDomain: {
		name: () => 'storycardsDomain' as const,
	},
	cardEditorAutoSync: {
		name: (version: string) => `storyVersions.${version}.data.cardEditorAutoSync`,
	},
	oncePerUser: {
		name: (version: string) => `storyVersions.${version}.settings.restrictions.oncePerUser`,
	},
	isDisabledApi: {
		name: (version: string) => `storyVersions.${version}.settings.restrictions.isDisabledApi`,
	},
	isHideCredit: {
		name: (version: string) => `storyVersions.${version}.settings.restrictions.isHideCredit`,
	},
	isAutoRedirect: {
		name: (version: string) => `storyVersions.${version}.settings.restrictions.isAutoRedirect`,
	},
	seoTitle: {
		name: (version: string) => `storyVersions.${version}.settings.seo.title`,
	},
	seoDescription: {
		name: (version: string) => `storyVersions.${version}.settings.seo.description`,
	},
	favicon: {
		name: (version: string) => `storyVersions.${version}.settings.seo.favicon`,
	},
	webclip: {
		name: (version: string) => `storyVersions.${version}.settings.seo.webclip`,
	},
	keywords: {
		name: (version: string) => `storyVersions.${version}.settings.seo.keywords`,
	},
	shareTitle: {
		name: (version: string) => `storyVersions.${version}.settings.share.title`,
	},
	shareDescription: {
		name: (version: string) => `storyVersions.${version}.settings.share.description`,
	},
	shareImage: {
		name: (version: string) => `storyVersions.${version}.settings.share.image`,
	},
	fonts: {
		name: (version: string) => `storyVersions.${version}.settings.fonts`,
	},
	gdprEnabled: {
		name: (version: string) => `storyVersions.${version}.settings.gdpr.enabled`,
	},
	gdprInteractionsDisabled: {
		name: (version: string) => `storyVersions.${version}.settings.gdpr.interactionsDisabled`,
	},
	dmAutoDetection: {
		name: (version: string) => `storyVersions.${version}.settings.darkMode.autoDetection`,
	},
	dmShowToggle: {
		name: (version: string) => `storyVersions.${version}.settings.darkMode.showToggle`,
	},
	dmEnableByUrl: {
		name: (version: string) => `storyVersions.${version}.settings.darkMode.enableByUrl`,
	},
	dmElements: {
		name: (version: string) => `storyVersions.${version}.settings.darkMode.elements`,
	},
};

export const VIEW_MAP = {
	GENERAL: 'general',
	SEO: 'seo',
	FONTS: 'fonts',
	INTEGRATION: 'integrations',
	RESTRICTIONS: 'restrictions',
	DARK_MODE: 'darkMode',
	GDPR: 'gdpr',
	ACTIVITY: 'activity',
	UPDATES: 'updates',
} as const;
